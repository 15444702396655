import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingView from '@/views/LandingView'
import ErrorView from '@/views/ErrorView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView,
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView')
  },
  {
    path: '/',
    name: 'auth-error',
    component: ErrorView,
    meta: { errorTitle: "Authentication error.", errorMessage: "There seems to be a problem with your login." }
  },
  {
    path: '/',
    name: 'error',
    component: ErrorView,
    meta: { errorTitle: "Internal server error.", errorMessage: "An unexpected error seems to have occurred." }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
