import { client, getAuthHeaders } from "./client";
import { gql } from 'graphql-request'

async function addEntry(word, definitions, language, includeAudio) {
    const query = gql`
        mutation addEntry($word: String!, $definitions: [String!]!, $language: Language!, $includeAudio: Boolean!) {
            addEntry(entry: { word: $word, definitions: $definitions, language: $language, includeAudio: $includeAudio }) {
                localAffected
                totalAffected
            }
        }
    `;

    const variables = {
        word,
        definitions,
        language,
        includeAudio,
    };

    const authHeaders = await getAuthHeaders();
    return client.rawRequest(query, variables, authHeaders);
}

async function deleteEntries(entries) {
    const query = gql`
        mutation deleteEntries($entries: [String!]!) {
            deleteEntries(entries: $entries) {
                localAffected
                totalAffected
            }
        }
    `;

    const variables = {
        entries
    };

    const authHeaders = await getAuthHeaders();
    return client.rawRequest(query, variables, authHeaders);
}

async function updateEntry(add, remove) {
    const query = gql`
        mutation updateEntry($add: DictionaryEntryInput!, $remove: String!) {
            updateEntries(
                updates: {
                    add: [$add],
                    remove: [$remove]
                }
            ) {
                localAffected
                totalAffected
            }
        }
    `;

    const variables = {
        add,
        remove,
    };

    const authHeaders = await getAuthHeaders();
    return client.rawRequest(query, variables, authHeaders);
}


export { addEntry, deleteEntries, updateEntry };