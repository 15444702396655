import { client, getAuthHeaders } from "./client";
import { gql } from 'graphql-request'

async function getEntries(cursorKey, limit, sortBy, sortOrder, wordFilter, definitionFilter, languageFilter) {
  const query = gql`
    query queryDictionary($cursorKey: String, $limit: Int!, $sortBy: SortBy, $sortOrder: SortOrder, $wordFilter: String, $definitionFilter: String, $languageFilter: Language) {
      dictionary(pagination: {cursorKey: $cursorKey, limit: $limit, sortBy: $sortBy, sortOrder: $sortOrder}, 
                filters: { wordSearch: $wordFilter, definitionSearch: $definitionFilter, language: $languageFilter }) {
        cursorKey
        results {
          word
          definitions
          language
          hasAudio
          lastUpdated
        }
      }
    }
  `;

  const variables = {
    cursorKey,
    limit: Number(limit),
    sortBy,
    sortOrder,
    wordFilter,
    definitionFilter,
    languageFilter,
  };

  const authHeaders = await getAuthHeaders();
  return client.rawRequest(query, variables, authHeaders);
}

async function getAvailableLanguages() {
  const query = gql`
    query queryAvailableLanguages {
      availableLanguages
    }
  `;

  const authHeaders = await getAuthHeaders();
  return client.rawRequest(query, {}, authHeaders);
}

async function fetchDefinition(word, language) {
  const query = gql`
    query fetchDefinition($word: String!, $language: Language!) {
      fetchDefinition(word: $word, language: $language) {
        definitions {
          partOfSpeech
          definition
        }
      }
    }
  `;

  const variables = {
    word,
    language,
  };

  const authHeaders = await getAuthHeaders();
  return client.rawRequest(query, variables, authHeaders);
}

async function fetchAudio(word, language) {
  const query = gql`
    query fetchAudio($word: String!, $language: Language!) {
      fetchAudio(word: $word, language: $language) {
        base64Data
      }
    }
  `;

  const variables = {
    word,
    language,
  };

  const authHeaders = await getAuthHeaders();
  return client.rawRequest(query, variables, authHeaders);
}

export { getEntries, getAvailableLanguages, fetchDefinition, fetchAudio };