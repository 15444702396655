<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { Auth, Cache } from "aws-amplify";
import router from "@/router";

export default {
  name: "App",

  data: function () {
    const darkThemeMedia = matchMedia("(prefers-color-scheme: dark)");
    return {
      darkThemeMedia,
      darkThemeMatch: darkThemeMedia.matches,
    };
  },

  watch: {
    darkThemeMatch(val) {
      this.$vuetify.theme.dark = val;
    },
    vuetifyDarkTheme(val) {
      this.applyBackground(val);
    },
  },

  computed: {
    vuetifyDarkTheme() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    applyBackground(val) {
      const body = document.body;

      if (val) {
        body.classList.add("loading-bg");
      } else {
        body.classList.remove("loading-bg");
      }
    },
    goToAuthError() {
      this.applyBackground(this.vuetifyDarkTheme);
      router.push({ name: "auth-error" });
    },
  },

  async mounted() {
    this.$vuetify.theme.dark = this.darkThemeMatch;

    this.darkThemeMedia.addListener(() => {
      this.darkThemeMatch = this.darkThemeMedia.matches;
    });

    // --- OAuth2 ---
    if (Cache.getItem("authError")) {
      this.goToAuthError();
      return;
    }

    let userSession = undefined;
    try {
      userSession = await Auth.currentAuthenticatedUser();
    } catch (err) {
      console.error(err);
    }

    if (userSession === undefined) {
      const authStatus = Cache.getItem("authStatus");

      if (!authStatus) {
        try {
          await Auth.federatedSignIn();
          return;
        } catch (err) {
          console.error(err);
          this.goToAuthError();
        }
      } else {
        console.error("Auth error");
        this.goToAuthError();
      }
    }

    Cache.removeItem("authStatus");

    this.applyBackground(this.vuetifyDarkTheme);
    router.push({ name: "home" });
  },
};
</script>

<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>