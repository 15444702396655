import { GraphQLClient } from "graphql-request";
import { Auth } from "aws-amplify";

const client = new GraphQLClient(process.env.VUE_APP_API_ENDPOINT);

async function getAuthHeaders() {
    const userSession = await Auth.currentSession();
    const jwtToken = userSession.getAccessToken().getJwtToken();
    const bearerToken = `Bearer ${jwtToken}`;

    const authHeaders = {
        authorization: bearerToken
    };

    return authHeaders;
}

export { client, getAuthHeaders }