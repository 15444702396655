import Vue from 'vue'
import Vuex from 'vuex'
import { Amplify, Hub, Cache, Auth } from 'aws-amplify';
import router from "@/router";
import * as api from "@/api";

Vue.use(Vuex)

Hub.listen('auth', ({ payload: { event } }) => {
  switch (event) {
    case 'signIn_failure':
      console.error('user sign in failed');
      Cache.setItem("authError", true);
      break;

  }
})

Amplify.configure({
  Auth: {
    userPoolId: 'eu-west-2_mL0q1QO5o',
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
    region: 'eu-west-2',
    oauth: {
      domain: 'mydictionary.auth.eu-west-2.amazoncognito.com',
      scope: ['graphql/standard', 'openid', 'profile'],
      redirectSignIn: process.env.VUE_APP_BASE_URL,
      redirectSignOut: process.env.VUE_APP_BASE_URL,
      responseType: 'code'
    },
    federationTarget: "COGNITO_USER_POOLS"
  }
});

export default new Vuex.Store({
  strict: false,
  state: {
    mode: 0,
    tableDataLoading: true,
    fetchTableDataFlag: false,
    availableLanguages: undefined,
    availableLanguagesLoadingState: false,
    processingWebRequest: false,
    selectedEntries: [],
    instanceId: undefined,
    requiredFormRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.trim().length > 0) || "Field cannot be empty",
    ],
    totalTiming: undefined,
    snack: {
      show: false,
      timeout: 4500,
      color: "",
      text: "",
    },
  },
  getters: {
    getRequiredFormRules: state => state.requiredFormRules,
    getLastServedBy: state => state.instanceId,
    getLastTotalTiming: state => state.totalTiming,
    isRequestFetchTableData: state => state.fetchTableDataFlag,
    isAvailableLanguagesInLoadingState: (state, getters) => !getters.isAvailableLanguagesReady && state.availableLanguagesLoadingState,
    isAvailableLanguagesReady: state => state.availableLanguages !== undefined,
    getAvailableLanguages: state => state.availableLanguages,
    isTableDataLoading: state => state.tableDataLoading,
    isInDefaultMode: state => state.mode === 0,
    isInDeleteMode: state => state.mode === 1,
    isInLogoutMode: state => state.mode === 999,
    isProcessingWebRequest: state => state.processingWebRequest,
    getSelectedEntries: state => state.selectedEntries,
    getSelectedEntriesCount: state => state.selectedEntries.length,
  },
  mutations: {
    setLastRequestInfo: (state, value) => {
      state.instanceId = value.instanceId;
      state.totalTiming = value.totalTiming;
    },
    requestFetchTableData: (state) => state.fetchTableDataFlag = true,
    clearFetchTableDataFlag: (state) => state.fetchTableDataFlag = false,
    setAvailableLanguagesLoadingState: (state, value) => (state.availableLanguagesLoadingState = value),
    setAvailableLanguages: (state, value) => (state.availableLanguages = value),
    setTableDataLoading: (state, value) => (state.tableDataLoading = value),
    setLogout: (state) => {
      state.mode = 999;
      Auth.signOut();
    },
    setDeleteMode: (state, value) => (state.mode = value ? 1 : 0),
    setProcessingWebRequest: (state, value) => (state.processingWebRequest = value),
    setSelectedEntries: (state, value) => (state.selectedEntries = value),
    resetSelectedEntries: state => (state.selectedEntries = []),
    setSuccessSnack: (state, value) => {
      state.snack = {
        show: true,
        timeout: 4500,
        color: "success",
        text: value,
      };
    },
    setWarningSnack: (state, value) => {
      state.snack = {
        show: true,
        timeout: 4500,
        color: "warning",
        text: value,
      };
    },
    setInfoSnack: (state, value) => {
      state.snack = {
        show: true,
        timeout: -1,
        color: "info",
        text: value,
      };
    },
    setErrorSnack: (state, value) => {
      state.snack = {
        show: true,
        timeout: 4500,
        color: "error",
        text: value,
      };
    },
    setSnackShow: (state, value) => {
      state.snack.show = value;
    },
  },
  actions: {
    parseLastRequest({ commit }, headers) {
      const headersMap = headers.map;

      const instanceId = headersMap["x-served-by"];
      let totalTiming = headersMap["server-timing"];

      if (totalTiming != undefined) {
        const splits = totalTiming.split('=');
        const [lastItem] = splits.slice(-1);
        totalTiming = Number(lastItem);
      }

      const value = {
        instanceId,
        totalTiming,
      };

      commit('setLastRequestInfo', value);
    },
    async fetchAvailableLanguages({ commit, dispatch, getters }) {
      if (getters.isAvailableLanguagesReady || getters.isAvailableLanguagesInLoadingState) {
        return;
      }

      commit('setAvailableLanguagesLoadingState', true);

      try {
        const { data, headers } = await api.query.getAvailableLanguages();
        dispatch('parseLastRequest', headers);
        commit('setAvailableLanguages', data.availableLanguages);
      } catch (err) {
        console.error(err);
        router.push({ name: "error" });
      }
    }
  },
  modules: {}
})
